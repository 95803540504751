import { useMemo } from "react";
import { useQuery } from "react-query";
import { formatISODate } from "../utils/formatDate";
import { fetchWithInterceptors } from "../utils/axiosInterceptors";
import { useGeotab } from "../contexts/GeotabContext";

const coreUrl = process.env.REACT_APP_CORE_URL;

export const useFetchReimburseEVData = (selectedVehicle, startDate, endDate) => {
  const { geotabSession } = useGeotab();

  const body = {
    start_date: formatISODate(startDate),
    end_date: formatISODate(endDate),
    serial_no: selectedVehicle,
    database: geotabSession.database,
    domain: "my.geotab.com",
  };

  const fetchReimburseEVData = async () => {
    try {
      const data = await fetchWithInterceptors(`${coreUrl}/reimburseevapi`, {
        method: "POST",
        body: JSON.stringify(body),
      });

      const events = data?.events || [];
      
      return {
        homeChargingEvents: events.sort((a, b) => {
          return (
            new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
          );
        }),
        alert: data?.alert,
        message: data?.message
      };
    } catch (error) {
      console.error("ReimburseEV API Error:", error);
      throw new Error("Error fetching charging events. Please try again later.");
    }
  };

  const {
    data: reimburseEVData,
    error: reimburseEVError,
    isLoading: isReimburseEVLoading,
  }: {
    data: {
      homeChargingEvents: {
        timestamp: string;
        KWH: string;
        reimbursement_amount: string;
        cost_per_kwh: string;
      }[];
      alert: string;
      message: string;
    };
    error: boolean;
    isLoading: boolean;
  } = useQuery(
    ["reimburseEVData", selectedVehicle, startDate, endDate],
    fetchReimburseEVData,
    {
      enabled: !!selectedVehicle,
    },
  );

  const chartData = useMemo(() => {
    if (!reimburseEVData) {
      return null;
    }
    
    if (reimburseEVError) {
      return null;
    }

    const labels = [];
    const kwh = [];
    const reimbursement = [];
    const rate = [];

    try {
      if (!reimburseEVData.homeChargingEvents?.length) {
        return null;
      }

      reimburseEVData.homeChargingEvents.forEach((event, index) => {
        try {
          if (!event.timestamp) {
            throw new Error(`Event ${index} missing timestamp`);
          }
          
          const timestamp = new Date(event.timestamp).toISOString().split("T")[0];
          const kwhValue = Number(event.KWH);
          const reimbursementValue = Number(event.reimbursement_amount);
          const rateValue = Number(event.cost_per_kwh);

          if (isNaN(kwhValue)) {
            throw new Error(`Invalid KWH value at index ${index}: ${event.KWH}`);
          }
          if (isNaN(reimbursementValue)) {
            throw new Error(`Invalid reimbursement value at index ${index}: ${event.reimbursement_amount}`);
          }
          if (isNaN(rateValue)) {
            throw new Error(`Invalid rate value at index ${index}: ${event.cost_per_kwh}`);
          }

          labels.push(timestamp);
          kwh.push(kwhValue);
          reimbursement.push(reimbursementValue);
          rate.push(rateValue);
        } catch (eventError) {
          console.error('Error processing charging event:', eventError);
        }
      });

      if (!labels.length) {
        throw new Error('No valid charging events could be processed');
      }

      const reimbursementCharge = {
        labels,
        datasets: [
          {
            label: "Reimbursement $",
            data: reimbursement,
            backgroundColor: "#58cab3",
            yAxisID: "y-reimbursement",
          },
          {
            label: "KWH",
            data: kwh,
            backgroundColor: "#007BFF",
            yAxisID: "y-kwh",
          },
        ],
      };

      const electricityCost = {
        labels,
        datasets: [
          {
            label: "Rate $/kWh",
            data: rate,
            backgroundColor: "#fe803d",
          },
        ],
      };

      return {
        reimbursementCharge,
        electricityCost,
      };
    } catch (error) {
      console.error('Error processing chart data:', error);
      return {
        error: error.message,
        reimbursementCharge: null,
        electricityCost: null,
      };
    }
  }, [reimburseEVData, reimburseEVError]);

  const calculateMetrics = (reimburseEVData) => {
    if (!reimburseEVData?.homeChargingEvents?.length) {
      return {
        totalKwh: 0,
        average: 0,
        totalReimbursed: 0,
        chargingSessions: 0
      };
    }

    const totalKwh = reimburseEVData.homeChargingEvents.reduce(
      (sum, event) => sum + Number(event.KWH || 0),
      0
    );

    const totalReimbursed = reimburseEVData.homeChargingEvents.reduce(
      (sum, event) => sum + Number(event.reimbursement_amount || 0),
      0
    );

    const chargingSessions = reimburseEVData.homeChargingEvents.length;
    
    const average = chargingSessions > 0 ? totalKwh / chargingSessions : 0;

    return {
      totalKwh: Number(totalKwh.toFixed(2)),
      average: Number(average.toFixed(2)),
      totalReimbursed: Number(totalReimbursed.toFixed(2)),
      chargingSessions
    };
  };

  const metrics = calculateMetrics(reimburseEVData);

  return {
    reimburseEVData,
    isReimburseEVLoading,
    reimburseEVError,
    chartData,
    ...metrics,
  };
};
