import React from "react";
import { Document, Page, Image, Text, View, Font } from "@react-pdf/renderer";
import { useFetchReimburseEVData } from "../api/useFetchReimburseEVData";

const addInUrl = process.env.REACT_APP_ADDIN_URL;

Font.register({
  family: "Poppins",
  src: "https://fonts.gstatic.com/s/poppins/v1/TDTjCH39JjVycIF24TlO-Q.ttf",
});

const now = new Date();
const year = now.getFullYear();
const month = (now.getMonth() + 1).toString().padStart(2, "0");
const day = now.getDate().toString().padStart(2, "0");

export const SingleVehicle = ({
  selectedVehicle,
  startDate,
  endDate,
  reimburseEVData,
}: any) => {
  const { totalKwh, average, totalReimbursed } = useFetchReimburseEVData(
    selectedVehicle?.serialNumber,
    startDate,
    endDate,
  );

  return (
    <Document>
      <Page
        style={{
          padding: 20,
          display: "flex",
          flex: 1,
          fontSize: 11,
          fontFamily: "Poppins",
        }}
      >
        <View
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Image style={{ width: 200 }} src={`${addInUrl}/moveev.png`} />
          <Text
            style={{ color: "#7b7b7b", fontSize: 9 }}
          >{`${month}-${day}-${year}`}</Text>
        </View>
        <View style={{ paddingVertical: 10 }}>
          <Text>{`Vehicle Name: ${selectedVehicle?.name || "-"}`}</Text>
          <Text>{`Serial Number: ${selectedVehicle?.serialNumber || "-"}`}</Text>
          <Text>{`VIN: ${selectedVehicle?.vin || "-"}`}</Text>
        </View>
        <Text style={{ textAlign: "center", color: "#1851be", fontSize: 14 }}>
          Home Charging Events Report
        </Text>
        <Text
          style={{ color: "#7b7b7b", fontSize: 9, textAlign: "center" }}
        >{`From ${new Date(startDate).toISOString().split("T")[0]} to ${new Date(endDate).toISOString().split("T")[0]}`}</Text>
        {
          <View style={{ paddingVertical: 10 }}>
            <Text>{`Total Reimbursed: $${totalReimbursed || 0}`}</Text>
            <Text>{`Total kWh Charged: ${totalKwh || 0} kWh`}</Text>
            <Text>{`Average kWh Charge per Session: ${isNaN(+average) ? 0 : average} kWh`}</Text>
            <Text>{`Charging Sessions: ${reimburseEVData?.homeChargingEvents?.length || 0}`}</Text>
          </View>
        }
        <View
          style={{
            borderBottom: "1.5px solid lightgray",
            display: "flex",
            width: "100%",
            flexDirection: "row",
            fontWeight: "bold",
            paddingVertical: 10,
            color: "#7b7b7b",
          }}
        >
          <Text style={{ flex: 1 }}>Event Date</Text>
          <Text style={{ flex: 1, textAlign: "center", fontWeight: "bold" }}>
            kWh Charged
          </Text>
          <Text style={{ flex: 1, textAlign: "center", fontWeight: "bold" }}>
            Cost per kWh
          </Text>
          <Text style={{ flex: 1, textAlign: "center", fontWeight: "bold" }}>
            Reimbursement
          </Text>
        </View>
        {reimburseEVData?.homeChargingEvents
          ?.sort((a, b) => {
            return (
              new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
            );
          })
          .map((event, i) => {
            const timestamp = new Date(event.timestamp)
              .toISOString()
              .split("T")[0]
              .split("-");
            const usaDate = `${timestamp[1]}-${timestamp[2]}-${timestamp[0]}`;
            return (
              <View
                key={i}
                style={{
                  borderBottom: "1px solid lightgray",
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  fontFamily: "Poppins",
                  paddingVertical: 10,
                }}
              >
                <Text
                  style={{ color: "#7b7b7b", flex: 1 }}
                >{`${usaDate}`}</Text>
                <Text
                  style={{ flex: 1, textAlign: "center", fontWeight: "bold" }}
                >{`${event.KWH?.toFixed(2)} kWh`}</Text>
                <Text
                  style={{ flex: 1, textAlign: "center", fontWeight: "bold" }}
                >
                  {`$${event.cost_per_kwh}`}
                </Text>
                <Text
                  style={{ flex: 1, textAlign: "center", fontWeight: "bold" }}
                >{`$${event.reimbursement_amount?.toFixed(2)}`}</Text>
              </View>
            );
          })}
      </Page>
    </Document>
  );
};
